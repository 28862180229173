import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import { TeliaHeading } from "@telia/b2x-telia-heading";

import {
  TeliaProductCardFrame,
  TeliaProductCardFrameProps,
} from "../product-card-frame/ProductCardFrame";
import styles from "./hardware-product-card.module.scss";

const rootClass = "telia-hardware-product-card";

export type TeliaHardwareProductCardProps = Omit<TeliaProductCardFrameProps, "children"> & {
  children?: ReactNode;
  alignment?: "left" | "center";
  preTitle?: string;
  title: string;
  imgSrc?: string;
  price?: ReactNode;
};

const HardwareProductCard = <T extends HTMLDivElement>(
  {
    active,
    href,
    badgeTitle,
    badgeVariant,
    paddingBottom,
    alignment = "center",
    preTitle,
    title,
    imgSrc,
    children,
    price,
    alt,
    ...rest
  }: TeliaHardwareProductCardProps,
  ref: ForwardedRef<T>
) => (
  <TeliaProductCardFrame
    active={active}
    href={href}
    badgeTitle={badgeTitle}
    badgeVariant={badgeVariant}
    paddingBottom={paddingBottom}
    {...rest}
    ref={ref}
  >
    <article
      className={`${styles[rootClass]} ${alignment === "left" ? styles[`${rootClass}--left`] : ""}`}
    >
      <div className={styles[rootClass + "__image"]}>
        <picture>
          <img src={imgSrc} alt={alt} />
        </picture>
      </div>
      <div className={styles[rootClass + "__content"]}>
        <TeliaHeading data-testid="heading" tag="h2" variant="title-200" disableHyphenation>
          {preTitle && <span className={styles[rootClass + "__pre-title"]}>{preTitle}</span>}
          <span className={styles[rootClass + "__title"]}>{title}</span>
        </TeliaHeading>
        <div>
          <div className={styles[rootClass + "__additional"]}>{children}</div>
          <div className={styles[rootClass + "__price"]}>{price}</div>
        </div>
      </div>
    </article>
  </TeliaProductCardFrame>
);

export const TeliaHardwareProductCard = forwardRef(HardwareProductCard);
TeliaHardwareProductCard.displayName = "TeliaHardwareProductCard";
