import React, { ForwardedRef, forwardRef } from "react";
import { ReactNode } from "react";
import { BadgeVariant, TeliaBadge } from "@telia/b2x-telia-badge";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import { arrowRight } from "@teliads/icons";

import styles from "./product-card-frame.module.scss";
const rootClass = "telia-product-card-frame";

export type TeliaProductCardFrameProps = React.HTMLProps<HTMLDivElement> & {
  children: ReactNode;
  id: string;
  active?: boolean;
  badgeVariant?: BadgeVariant;
  badgeTitle?: string;
  paddingBottom?: boolean;
  ctaText?: string;
  className?: string;
  linkText?: string;
  ["data-testid"]?: string;
};

const createClassString = (classes: (null | undefined | false | string)[]) =>
  classes.filter((c): c is string => !!c).join(" ");

const ProductCardFrame = <T extends HTMLDivElement>(
  {
    children,
    active,
    badgeVariant,
    badgeTitle,
    paddingBottom,
    href,
    className,
    ctaText,
    linkText,
    ["aria-label"]: ariaLabel,
    ...divProps
  }: TeliaProductCardFrameProps,
  ref: ForwardedRef<T>
) => {
  const cardContainerClassName = createClassString([
    className,
    styles[rootClass],
    active && styles[rootClass + "--active"],
    paddingBottom && styles[rootClass + "--padding-bottom"],
  ]);

  return (
    <div className={cardContainerClassName} ref={ref} {...divProps}>
      {badgeVariant && badgeTitle && (
        <div className={`${styles[rootClass + "__badge"]}`}>
          <TeliaBadge variant={badgeVariant || "attention"}>{badgeTitle}</TeliaBadge>
        </div>
      )}
      {children}
      {href && (
        <a aria-label={ariaLabel} className={styles[rootClass + "__anchor"]} href={href}>
          <span className={styles[rootClass + "__anchor-text"]}>{linkText}</span>
        </a>
      )}
      {ctaText && (
        <div className={styles[rootClass + "__cta-container"]}>
          <div className={styles[rootClass + "__cta"]} role="button">
            {ctaText}
            <TeliaIcon svg={arrowRight.svg} size="sm" />
          </div>
        </div>
      )}
    </div>
  );
};

export const TeliaProductCardFrame = forwardRef(ProductCardFrame);
TeliaProductCardFrame.displayName = "TeliaProductCardFrame";
