import React, { ForwardedRef, forwardRef } from "react";
import { ReactNode } from "react";
import { TeliaHeading } from "@telia/b2x-telia-heading";
import { TeliaP } from "@telia/b2x-telia-p";

import {
  TeliaProductCardFrame,
  TeliaProductCardFrameProps,
} from "../product-card-frame/ProductCardFrame";
import styles from "./service-product-card.module.scss";

const rootClass = "telia-service-product-card";

export type TeliaServiceProductCardProps = Omit<TeliaProductCardFrameProps, "children"> & {
  heading: string;
  priceHeading: string;
  pricePreheading?: string;
  priceSubheading?: string;
  richText?: string;
  compact?: boolean;
  logotypes?: ReactNode;
};

const ServiceProductCard = <T extends HTMLDivElement>(
  {
    active,
    href,
    badgeTitle,
    badgeVariant,
    paddingBottom,
    heading,
    priceHeading,
    pricePreheading,
    priceSubheading,
    richText,
    compact,
    logotypes,
    ...rest
  }: TeliaServiceProductCardProps,
  ref: ForwardedRef<T>
) => (
  <TeliaProductCardFrame
    linkText={heading}
    active={active}
    href={href}
    badgeTitle={badgeTitle}
    badgeVariant={badgeVariant}
    paddingBottom={paddingBottom}
    {...rest}
    ref={ref}
  >
    <div className={styles[rootClass + "__container"]}>
      <div className={styles[rootClass + "__block"]}>
        <TeliaHeading
          tag="h3"
          variant="title-200"
          className={`${styles[rootClass + "__title"]}`}
          data-testid="heading"
          disableHyphenation
        >
          {heading}
        </TeliaHeading>
        {richText && (
          <div
            data-testid="rich-text"
            dangerouslySetInnerHTML={{ __html: richText }} // eslint-disable-line react/no-danger
            className={`${styles[rootClass + "__rich-text"]} ${
              compact ? styles[rootClass + "__rich-text--show-only-first-bullet"] : ""
            }`}
          />
        )}
      </div>
      <div className={styles[rootClass + "__block"]}>
        {logotypes && (
          <div data-testid="logotypes" className={styles[rootClass + "__logotypes-container"]}>
            {logotypes}
          </div>
        )}
        <div className={styles[rootClass + "__price-container"]}>
          <TeliaHeading
            tag="h4"
            variant="title-100"
            className={styles[rootClass + "__price-heading"]}
            data-testid="price-heading"
          >
            {pricePreheading && (
              <span className={styles[rootClass + "__price-preheading"]}>
                {pricePreheading + " "}
              </span>
            )}
            {priceHeading}
          </TeliaHeading>
          {priceSubheading && (
            <TeliaP
              data-testid="price-sub-heading"
              className={styles[rootClass + "__price-subheading"]}
            >
              {priceSubheading}
            </TeliaP>
          )}
        </div>
      </div>
    </div>
  </TeliaProductCardFrame>
);

export const TeliaServiceProductCard = forwardRef(ServiceProductCard);
TeliaServiceProductCard.displayName = "TeliaServiceProductCard";
